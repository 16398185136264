import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './CTA.scss';

class CTA extends Component {
  getText = (name) => {
    switch (name) {
      case 'gallery':
        return 'See all cards in a gallery layout';
      case 'list':
        return 'See a listing of all cards';
      case 'search':
        return 'Search all cards based on attributes';
      default:
        return false;
    }
  }

  render() {
    return (
      <NavLink to={this.props.url} className={"cta card " + this.props.name}>
        <div className="image">
        </div>
        <i className="material-icons">{this.props.icon}</i>
        <h3>{this.props.title}</h3>
        <p>{this.getText(this.props.name)}</p>
      </NavLink>
    )
  }
};

export default CTA;