import React from 'react';
import { NavLink } from 'react-router-dom';

const navItem = (props) => (
  <li><NavLink
    activeClassName="active"
    exact
    to={props.url}
  >
    <div className="box-3d">
      <div className="front">{props.label}</div>
      <div className="hover">{props.label}</div>
    </div>
  </NavLink></li>
);

export default navItem;