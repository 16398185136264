import React from 'react';
import './Loader.scss';
import spinner from '../../../assets/images/spinner.gif';

const loader = () => (
  <div className="loader">
    <img src={spinner} alt="Spinner" />
    <br />LOADING
  </div>
);

export default loader;