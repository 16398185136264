import React from 'react';
import Thumb from './GalleryThumb/GalleryThumb';
import Marker from '../../Marker/Marker';
import './GalleryItem.scss';

const item = (props) => (
  <div className="item card" onClick={props.clicked}>
    <Thumb bid={props.bid} back={false} />
    <div className="card-details">
      <div className="card-number">
        {props.number ? "#" + props.number : null}
      </div>
      <div>{props.year ? props.year : "(Year Unknown)"}</div>
      <div className="description">
        {props.brand !== '' ? props.brand + ' ' : null}
        {props.set_name !== '' ? props.set_name + ' ' : null}
        {props.other_info !== '' ? props.other_info + ' ' : null}
        <div className="attr">
          {props.mem === 1 ? <Marker type="mem" /> : null}
          {props.auto === 1 ? <Marker type="auto" /> : null}
        </div>
        {props.print ?
          <div className="card-print">
            <div className="marker print">{props.ser}/{props.print}</div>
          </div>
          : null
        }
      </div>
    </div>
  </div>

);

export default item;