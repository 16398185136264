import React, { Component } from 'react';
import './GalleryThumb.scss';

class thumb extends Component {
  state = {
    loading: false
  }

  render() {
    const front = (
      <div className="front" style={{ backgroundImage: "url(http://patrickroycards.com/cards/" + this.props.bid + "-f.jpg)" }}></div>
    );
    const back = this.props.back
      ? <div className="back" style={{ backgroundImage: "url(http://patrickroycards.com/cards/" + this.props.bid + "-b.jpg)" }}></div>
      : null;

    return (
      <div className="thumb">
        {front}
        {back}
      </div>
    )
  }
}

export default thumb;