import React from 'react';
import './Header.scss';
import ImgNr33 from '../../assets/images/patrick-roy-number-33.png';
import ImgAuto from '../../assets/images/patrick-roy-autograph.png';
import ImgSave from '../../assets/images/patrick-roy-save.png';

const header = () => (
  <header className="no-shrink">
    <div className="container">
      <a href="/" className="icon">
        <img src={ImgNr33} alt="Patrick Roy Cards.com #33" />
      </a>
      <h1>Patrick Roy Cards<span>.com</span></h1>
      <img src={ImgAuto} className="autograph" alt="Patrick Roy Autograph" />
      <img src={ImgSave} className="save" alt="Patrick Roy Save" />
    </div>
  </header >
);

export default header;