import React from 'react';
import './ShowingCount.scss';

const showingCount = (props) => (
  <div className="showing">
    Showing&nbsp;
    {props.from ? props.from + " - " : null}
    {props.to} of {props.total} cards
  </div>
);

export default showingCount;
