import React from 'react';
import './Marker.scss';

const marker = (props) => {
  let classes = ['marker'];
  switch (props.type) {
    case 'mem':
      classes.push('mem');
      break;
    case 'auto':
      classes.push('auto');
      break;
    case 'print':
      classes.push('print');
      break;
    case 'ser':
      classes.push('print');
      break;
    default:
      break;
  }

  let values = "";
  if (props.ser) {
    values = props.ser + "/" + props.print;
  } else if (props.print) {
    values = "/" + props.print;
  }

  return (
    <div className={classes.join(' ')}>
      {values}
    </div>
  )
}

export default marker;