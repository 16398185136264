import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';
import './CardDetails.scss';
import Marker from '../Marker/Marker';
import axios from 'axios';

class cardDetails extends Component {
  state = {
    card: {}
  }

  getCardDetails = () => {
    const cardId = this.props.location.search.replace('?id=', '');

    axios.get("http://patrickroycards.com/api?c=" + cardId)
      .then(response => {
        this.setState({ card: response.data.cards[0] });
      }).catch(error => {
        // console.error("Get cards: " + error);
      });
  }

  componentWillMount() {
    this.getCardDetails();
  }

  render() {
    let description = '';
    description += this.state.card.year ? this.state.card.year : "";
    description += this.state.card.brand ? " " + this.state.card.brand : "";
    description += this.state.card.set_name ? " " + this.state.card.set_name : "";
    description += this.state.card.other_info ? " " + this.state.card.other_info : "";
    description += this.state.card.number ? " #" + this.state.card.number : "";
    description += this.state.card.print ? " /" + this.state.card.print : "";

    const mem = (this.state.card.mem) ? <Marker type="mem" /> : null;
    const auto = (this.state.card.auto) ? <Marker type="auto" /> : null;
    const print = (this.state.card.print) ? <Marker type="print" print={this.state.card.print} /> : null;
    const ser = (this.state.card.ser) ? <Marker type="ser" ser={this.state.card.ser} print={this.state.card.print} /> : null;

    const meta = {
      title: 'PRC - ' + description,
      description: 'PatrickRoyCards.com - ' + description
    }

    return (
      <DocumentMeta {...meta}>
        <div className="container top card-details-page">
          <div className="card">
            <div className="card-details">
              <h2>Card Details</h2>

              {description}
              <div className="markers">
                {mem}
                {auto}
                {print}
              </div>

              <div className="thumb-wrapper">
                <div className="thumb">
                  <div className="front" style={{ backgroundImage: "url(http://patrickroycards.com/cards/" + this.state.card.bid + "-f.jpg)" }}></div>
                  <div className="back" style={{ backgroundImage: "url(http://patrickroycards.com/cards/" + this.state.card.bid + "-b.jpg)" }}></div>
                </div>
              </div>

              <div className="card-info">
                {this.state.card.year ? <div><div className="label">Year:</div>{this.state.card.year}</div> : null}
                {this.state.card.brand ? <div><div className="label">Brand:</div>{this.state.card.brand}</div> : null}
                {this.state.card.set_name ? <div><div className="label">Set:</div>{this.state.card.set_name}</div> : null}
                {this.state.card.other_info ? <div><div className="label"></div>{this.state.card.other_info}</div> : null}
                {this.state.card.number ? <div><div className="label">Number:</div>#{this.state.card.number}</div> : null}
                <div className="attr-wrap">
                  {print ? <div className="attr"><div className="label">Print Run: </div>
                    <Marker type="print" print={this.state.card.print} />
                  </div> : null}

                  {ser ? <div className="attr"><div className="label">Serial #: </div>
                    <Marker type="print" ser={this.state.card.ser} print={this.state.card.print} />
                  </div> : null}

                  {mem || auto ? <div className="attr"><div className="label">Attributes: </div>
                    <div className="marker-wrap">{mem}</div>
                    <div className="marker-wrap">{auto}</div>
                  </div> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta >
    );
  }
}

export default cardDetails;
