import React, { Component } from 'react';
import NavItem from './NavItem/NavItem';
import './Navigation.scss';

class navigation extends Component {
  state = {
    navItems: {
      gallery: { label: "Gallery", url: "/card-gallery" },
      list: { label: "List", url: "/card-list" },
      search: { label: "Search", url: "/search" }
      // doubles: { label: "Doubles", url: "/doubles" }
      // blog: { label: "Blog", url: "/blog", icon: "library_books" }
    }
  }

  render() {
    const navItems = [];
    for (let key in this.state.navItems) {
      navItems.push({
        id: key,
        label: this.state.navItems[key].label,
        url: this.state.navItems[key].url,
        icon: this.state.navItems[key].icon
      });
    }

    let nav = navItems.map(navItem => (
      <NavItem
        key={navItem.label}
        label={navItem.label}
        url={navItem.url}
        icon={navItem.icon}
      />
    ));

    return (
      <nav id="MainNav" className="no-shrink">
        <div className="container">
          <ul id="Nav">
            {nav}
          </ul>
          {/* <Search /> */}
        </div>
      </nav>
    );
  }
}

export default navigation;
