import React from 'react';
import Marker from '../../Marker/Marker';
import './ListItem.scss';

const item = (props) => (
  <li className="item" onClick={props.clicked}>
    <div className="attr">
      {props.mem === 1 ? <Marker type="mem" /> : null}
      {props.auto === 1 ? <Marker type="auto" /> : null}
    </div>
    <div className="print">{props.print ? props.ser + "/" + props.print : null}</div>
    <div className="number">{props.number ? "#" + props.number : null}</div>
    <div className="description">
      {props.year ? props.year + " " : null}
      {props.brand !== "" ? props.brand + " " : null}
      {props.set_name !== "" ? props.set_name + " " : null}
      {props.other_info !== "" ? props.other_info + " " : null}
    </div>
  </li>
);

export default item;