import React, { Component } from 'react';
import CTA from './CTA/CTA';
import './Home.scss';

import axios from 'axios';

class home extends Component {
  state = {
    opacity: 0,
    top: "50px",
    total: 0,
    ctas: [
      {
        name: "gallery",
        icon: "view_module",
        title: "Card Gallery",
        url: "card-gallery"
      }, {
        name: "list",
        icon: "list",
        title: "Card List",
        url: "card-list"
      }, {
        name: "search",
        icon: "search",
        title: "Card Search",
        url: "search"
      }
    ]
  }

  formatNumber(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  componentWillMount() {
    this.getTotal();
  }

  getTotal = () => {
    axios.get("http://patrickroycards.com/api?t")
      .then(response => {
        const newTotal = response.data;
        this.setState({ total: newTotal }, this.updateTotal);
      }).catch(error => {
        console.error("Get total: " + error);
      });
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        opacity: 1,
        top: 0
      });
    }, 20);
  }

  render() {
    const ctas = [];
    for (let key in this.state.ctas) {
      ctas.push({
        title: this.state.ctas[key].title,
        name: this.state.ctas[key].name,
        icon: this.state.ctas[key].icon,
        url: this.state.ctas[key].url
      });
    }

    let renderCTAs = ctas.map(cta => (
      <CTA
        key={cta.name}
        title={cta.title}
        name={cta.name}
        icon={cta.icon}
        url={cta.url}
      />
    ));

    return (
      <div className="container fade" style={{ opacity: this.state.opacity, top: this.state.top }}>
        <div id="Home">
          <h2>Welcome to <span className="no-wrap">PatrickRoyCards.com</span></h2>
          <p className="text">This site is a listing and showcase of my<br /> ever-growing Patrick Roy card collection.</p>

          <section className="count">
            <p className="text">Current collection size:</p>
            <div>{this.formatNumber(this.state.total)}</div>
          </section>

          <div className="ctas">
            {renderCTAs}
          </div>
        </div>
      </div>
    )
  }
};

export default home;