import React, { Component } from 'react';
import Loader from '../../UI/Loader/Loader';
import Item from './Item/GalleryItem';
import ShowingCount from '../ShowingCount/ShowingCount';
import '../../Cards/Cards.scss';

import axios from 'axios';

class cards extends Component {
  state = {
    items: 196,
    cards: [],
    total: 0,
    loading: false,
    button: false
  }

  formatNumber(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  setTotal = () => {
    axios.get("http://patrickroycards.com/api?t")
      .then(response => {
        const newTotal = response.data;
        this.setState({ total: newTotal }, this.getCards);
      }).catch(error => {
        console.error("Get total: " + error);
      });
  }

  getCards = () => {
    this.setState({ loading: true });
    if (this.state.cards.length <= this.state.total) {
      this.setState({ button: true });
    }

    let items = this.state.items;
    let total = this.state.total;

    if (parseInt(this.state.cards.length + items) > total) {
      items = total - this.state.cards.length;
      console.log(total + " - " + this.state.cards.length);
      this.setState({ button: false })
    } else {
      this.setState({ button: true })
    }

    const params = {
      b: this.state.cards.length,
      i: items
      // b: 1852,
      // i: 21
    }

    axios.get("http://patrickroycards.com/api", { params })
      .then(response => {
        let newCards = this.state.cards;
        for (let key in response.data.cards) {
          newCards.push(response.data.cards[key]);
        }
        this.setState(newCards);
        this.setState({ loading: false });
      }).catch(error => {
        console.error("Get cards: " + error);
      });
  }

  componentWillMount() {
    this.setTotal();
  }

  handleItemClick = cardId => {
    console.log('clicked')
    this.props.history.push('/card-details?id=' + cardId);
  }

  render() {
    const cardItems = [];
    for (let key in this.state.cards) {
      cardItems.push({
        id: this.state.cards[key].id,
        bid: this.state.cards[key].bid,
        number: this.state.cards[key].number,
        year: this.state.cards[key].year,
        brand: this.state.cards[key].brand,
        set_name: this.state.cards[key].set_name,
        other_info: this.state.cards[key].other_info,
        ser: this.state.cards[key].ser,
        print: this.state.cards[key].print,
        mem: this.state.cards[key].mem,
        auto: this.state.cards[key].auto
      });
    }

    let renderCardItems = cardItems.map(cardItem => (
      <Item
        key={cardItem.id}
        id={cardItem.id}
        bid={cardItem.bid}
        number={cardItem.number}
        year={cardItem.year}
        brand={cardItem.brand}
        set_name={cardItem.set_name}
        other_info={cardItem.other_info}
        ser={cardItem.ser}
        print={cardItem.print}
        mem={cardItem.mem}
        auto={cardItem.auto}
        clicked={() => { this.handleItemClick(cardItem.id) }}
      />
    ));

    const spinner = this.state.loading ? <Loader /> : null;

    let buttonClasses = ['button'];
    if (this.state.loading) {
      buttonClasses.push('hidden');
    }
    buttonClasses = buttonClasses.join(' ');

    let button =
      this.state.button ?
        <div
          onClick={() => this.getCards(this.state.cards.length + 1)}
          id="LoadMore"
          className={buttonClasses}>
          Load More
      </div>
        : null

    return (
      <div className="container top no-shrink listing">
        <div className="items-gallery">
          {renderCardItems}
        </div>
        <div className="more">
          {button}
          {spinner}
        </div>
        <ShowingCount to={this.formatNumber(this.state.cards.length)} total={this.formatNumber(this.state.total)} />
      </div>
    );
  }
}

export default cards;
