import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from './components/Header/Header';
import Navigation from './components/Navigation/Navigation';
import Home from './components/Home/Home';
import Gallery from './components/Cards/Gallery/GalleryCards';
import List from './components/Cards/List/ListCards';
import Search from './components/Search/Search';
import CardDetails from './components/Cards/CardDetails/CardDetails';
import './scss/global.scss';

class App extends Component {
  render() {
    let routes = (
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/card-gallery" exact component={Gallery} />
        <Route path="/card-list" exact component={List} />
        <Route path="/search" exact component={Search} />
        <Route path="/card-details" exact component={CardDetails} />
      </Switch>
    );

    return (
      <div>
        <Header />
        <Navigation />
        {routes}
      </div>
    );

  }
}

export default App;
