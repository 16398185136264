import React, { Component } from 'react';
import './Search.scss';
import Loader from '../UI/Loader/Loader';
import Item from '../Cards/List/ListItem/ListItem';
import ToggleSwitch from '../UI/toggleSwitch/toggleSwitch';
import axios from 'axios';

class search extends Component {
  state = {
    cards: [],
    total: null,
    searched: false,
    collapsed: false,
    advanced: false,
    loading: false,
    description: "",
    print: 0,
    printType: "eq",
    mem: 2,
    auto: 2
  }

  handleCollapseClick = () => { this.setState({ collapsed: !this.state.collapsed }); }
  toggleAdvancedSearch = () => { this.setState({ advanced: !this.state.advanced }) }
  handleTextChange = event => { this.setState({ description: event.target.value }); }
  handlePrintChange = event => { this.setState({ print: event.target.value }); }
  handlePrintTypeChange = event => { this.setState({ printType: event.target.value }); }

  handleMemChange = event => {
    const setMem = event.target.checked ? 1 : 2;
    this.setState({ mem: setMem });
  }

  handleAutoChange = event => {
    const setAuto = event.target.checked ? 1 : 2;
    this.setState({ auto: setAuto });
  }

  handleItemClick = cardId => {
    this.props.history.push('/card-details?id=' + cardId);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true, searched: true, cards: [] });

    const params = {
      s: "",
      m: this.state.mem,
      a: this.state.auto,
      p: this.state.print,
      d: this.state.description
    };

    axios.get("http://patrickroycards.com/api/?", { params })
      .then(response => {
        let searchCards = this.state.cards;

        this.setState({ total: response.data.total });

        for (let key in response.data.cards) {
          searchCards.push(response.data.cards[key]);
        }
        this.setState(searchCards);
        if (searchCards.length) {
          this.setState({ collapsed: true })
        }
        this.setState({ loading: false });
      }).catch(error => {
        // console.error("[Error]: " + error);
      });
  }

  render() {
    let itemsFound = null;
    if (!this.state.loading) {
      let itemsFoundClasses = ['total-shown', 'card'];
      let numberOfItemsFound = "";
      if (this.state.searched) {
        if (this.state.total === 0) {
          itemsFoundClasses.push('show none');
          numberOfItemsFound = "No cards found matching the search criteria";
        } else if (this.state.total === 1) {
          numberOfItemsFound = "1 card found";
        } else if (this.state.total > 1) {
          numberOfItemsFound = this.state.total + " cards found";
        }
        itemsFoundClasses.push('show');
      }
      itemsFound = (
        <div className={itemsFoundClasses.join(' ')}>
          {numberOfItemsFound}
        </div>
      );
    } else {
      itemsFound = <Loader />
    }

    let advancedClasses = ['advanced'];
    let inputWrapperClasses = ['input-wrapper']
    if (this.state.advanced) {
      advancedClasses.push('show');
      inputWrapperClasses.push('hide');
    }

    let listClasses = ["no-shrink", "listing"];
    if (!this.state.cards.length) {
      listClasses.push('collapsed');
    }

    const cardItems = [];
    for (let key in this.state.cards) {
      cardItems.push({
        id: this.state.cards[key].id,
        bid: this.state.cards[key].bid,
        number: this.state.cards[key].number,
        year: this.state.cards[key].year,
        brand: this.state.cards[key].brand,
        set_name: this.state.cards[key].set_name,
        other_info: this.state.cards[key].other_info,
        ser: this.state.cards[key].ser,
        print: this.state.cards[key].print,
        mem: this.state.cards[key].mem,
        auto: this.state.cards[key].auto
      });
    }

    let renderCardItems = cardItems.map(cardItem => (
      <Item
        key={cardItem.id}
        id={cardItem.id}
        bid={cardItem.bid}
        number={cardItem.number}
        year={cardItem.year}
        brand={cardItem.brand}
        set_name={cardItem.set_name}
        other_info={cardItem.other_info}
        ser={cardItem.ser}
        print={cardItem.print}
        mem={cardItem.mem}
        auto={cardItem.auto}
        clicked={(event) => { this.handleItemClick(cardItem.id) }}
      />
    ));

    let collpaseClasses = ['search-toggle button'];
    let cardClasses = ['card'];
    if (this.state.collapsed) {
      collpaseClasses.push('collapsed');
      cardClasses.push('collapsed');
    } else {
      collpaseClasses.push('open');
    }

    let searchCollapse = (
      <div className={collpaseClasses.join(' ')} onClick={this.handleCollapseClick}>
        <i className="material-icons"></i>
      </div>
    )

    return (
      <div className="search container">
        <div className={cardClasses.join(' ')}>
          {searchCollapse}
          <h2>Card Search</h2>
          <form onSubmit={this.handleSubmit}>
            <div className="form-element switch-container" style={{ display: "none" }}>
              <label>Advanced Search: </label>
              <ToggleSwitch
                id="Switch"
                checked={this.state.advanced}
                changed={this.toggleAdvancedSearch} />
            </div>

            <div className="form-element description">
              {/* <label htmlFor="Description">Description:</label> */}
              <label htmlFor="Description">Search:</label>
              <div className="input-wrapper">
                <input id="Description" name="Description" type="text"
                  onChange={(event) => this.handleTextChange(event)} />
              </div>

              <div className={advancedClasses.join(' ')}>
                <label htmlFor="Description">Exclude:</label>
                <div className="input-wrapper">
                  <input id="DescriptionEx" name="DescriptionEx" type="text" />
                </div>
              </div>
            </div>

            <div className="form-element" style={{ display: "none" }}>
              <label htmlFor="Number">Card Number:</label>
              <div className="input-wrapper">
                <input type="text" id="Number" name="Number" />
              </div>
            </div>

            <div className="form-element radio">
              <label htmlFor="Print">Print Runt:</label>
              <div className="input-wrapper">
                <div className={advancedClasses.join(' ')}>
                  <div className="toggle">
                    <input onChange={this.handlePrintTypeChange} type="radio"
                      name="Print" id="PrintEq"
                      value="eq" checked={this.state.print === "eq"} />
                    <label className="radio" htmlFor="PrintEq">=</label>

                    <input onChange={this.handlePrintTypeChange} type="radio"
                      name="Print" id="PrintGt"
                      value="gt" checked={this.state.print === "gt"} />
                    <label className="radio" htmlFor="PrintGt">&gt;=</label>

                    <input onChange={this.handlePrintTypeChange} type="radio"
                      name="Print" id="PrintLt"
                      value="lt" checked={this.state.print === "lt"} />
                    <label className="radio" htmlFor="PrintLt">&lt;=</label>
                  </div>
                </div>

                <input type="number" id="Print" name="Print" min="1"
                  onChange={(event) => this.handlePrintChange(event)} />
              </div>
            </div>

            <div className="form-element attributes">
              <label>Attributes:</label>
              <div className={inputWrapperClasses.join(' ')}>
                <label htmlFor="AttrMem" className="marker mem">
                  <input onChange={this.handleMemChange} type="checkbox" id="AttrMem" name="AttrMem" value="1" />
                </label>
                <div className={advancedClasses.join(' ')}>
                  <div className="toggle">
                    <input onChange={this.handleMemChange} type="radio"
                      name="AttrMem" id="AttrMem2"
                      value="2" checked={this.state.mem === 2} />
                    <label className="radio" htmlFor="AttrMem2">Either</label>

                    <input onChange={this.handleMemChange} type="radio"
                      name="AttrMem" id="AttrMem1"
                      value="1" checked={this.state.mem === 1} />
                    <label className="radio" htmlFor="AttrMem1">Yes</label>

                    <input onChange={this.handleMemChange} type="radio"
                      name="AttrMem" id="AttrMem0"
                      value="0" checked={this.state.mem === 0} />
                    <label className="radio" htmlFor="AttrMem0">No</label>
                  </div>
                </div>
              </div>
              <div className={inputWrapperClasses.join(' ')}>
                <label htmlFor="AttrAuto" className="marker auto">
                  <input onChange={this.handleAutoChange} type="checkbox" id="AttrAuto" name="AttrAuto" value="1" />
                </label>
                <div className={advancedClasses.join(' ')}>
                  <div className="toggle">
                    <input onChange={this.handleAutoChange} type="radio"
                      id="AttrAuto2" name="AttrAuto"
                      value="2" checked={this.state.auto === 2} />
                    <label className="radio" htmlFor="AttrAuto2">Either</label>

                    <input onChange={this.handleAutoChange} type="radio"
                      name="AttrAuto" id="AttrAuto1"
                      value="1" checked={this.state.auto === 1} />
                    <label className="radio" htmlFor="AttrAuto1">Yes</label>

                    <input onChange={this.handleAutoChange} type="radio"
                      name="AttrAuto" id="AttrAuto0"
                      value="0" checked={this.state.auto === 0} />
                    <label className="radio" htmlFor="AttrAuto0">No</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-element submit">
              <button className="button" type="submit">Search</button>
            </div>
          </form>
        </div>

        {itemsFound}

        <div className={listClasses.join(' ')}>
          <ul className="list-items">
            <li className="header">
              <div className="attr">Attr</div>
              <div className="print">Print Run</div>
              <div className="number">Number</div>
              <div className="description">Description</div>
            </li>
            {renderCardItems}
          </ul>
        </div>
      </div>
    )
  }
}

export default search;