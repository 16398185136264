import React from 'react';
import './toggleSwitch.scss';

const toggleSwitch = (props) => (
  <div>
    <input
      id={props.id}
      checked={props.checked}
      type="checkbox"
      className="input"
      onChange={props.changed}
    />
    <label className="switch" htmlFor={props.id}>
      <div className="background"></div>
      <div className="dot"></div>
      <div className="state"></div>
    </label>
  </div>
);

export default toggleSwitch;